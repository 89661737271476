import { requestDelete, requestGet, requestPost, requestPut } from '@configs';
import { getUserToken } from '@utils';
import { GET_SCHEDULES, IS_SUCCESS } from '@type';
import { showLoading } from '@store/actions';
import { notification } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { reportSentry } from '../../../../utils/Utils';
import { BASE_API_URL, BASE_VERSION } from '../../../../constants/initHttp';

export const createSchedule = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost('schedules?overlap=false', data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Create Schedule Success',
        });
        dispatch(showLoading(false));
        return true;
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create Schedule Failed',
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};
export const createScheduleV2 = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost('schedules_bulk?overlap=false', data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Create Schedule Success',
        });
        dispatch(showLoading(false));
        return true;
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create Schedule Failed',
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};
export const createScheduleRange = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost('schedules-range?overlap=false', data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Create Schedule Success',
        });
        dispatch(showLoading(false));
        return true;
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Create Schedule Failed',
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};

export const getSchedule = (classID, role, limit, page, date, dateAfter) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(
    `schedules/class/${classID}?access=${role}&type=${role}&limit=${limit}&page=${page}&sortBy=start_date&start_date_after_equal=${date}&start_date_before=${dateAfter}&order=asc`,
    false,
    getUserToken()
  )
    .then((response) => {
      let nearestDate;
      response.data.list_data.forEach((value) => {
        const diff = moment(value.start_date).diff(moment(), 'minutes');
        if (diff > 0) {
          if (nearestDate) {
            if (moment(value.start_date).diff(moment(), 'days') < 0) {
              nearestDate = value;
            }
          } else {
            nearestDate = value;
          }
        }
      });
      if (nearestDate) {
        const popit = response.data.list_data.filter((e) => e.ID !== nearestDate.ID);
        response.data.list_data = [nearestDate].concat(popit);
      }
      dispatch({ type: GET_SCHEDULES, value: response.data });
      dispatch(showLoading(false));
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};
export const getScheduleOverlap = (start, end) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(`schedules/checkOverlap?start_date=${start}&end_date=${end}`, false, getUserToken())
    .then((response) => {
      dispatch(showLoading(false));
      return response.data;
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};
export const getScheduleOverlapBulk = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await axios({
    url: `${BASE_API_URL}${BASE_VERSION}schedules/checkOverlapBulk`,
    method: 'POST',
    headers: {
      Authorization: `${getUserToken()}`,
    },
    data,
  })
    .then((response) => {
      dispatch(showLoading(false));
      return response.data?.data;
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error?.response?.data?.message,
      });
      return null;
    });
  return api;
};
export const getScheduleIndicator = (start, end) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(`schedules/date?start_date=${start}&end_date=${end}`, false, getUserToken())
    .then((response) => {
      dispatch(showLoading(false));
      return response.data;
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};
export const getOneSchedule = (scheduleId) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(`schedules/${scheduleId}`, false, getUserToken())
    .then((response) => {
      dispatch(showLoading(false));
      return response;
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};
export const getLastSchedule = (date) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestGet(`schedules/last?end_date=${date}`, false, getUserToken())
    .then((response) => {
      dispatch(showLoading(false));
      return response;
    })
    .catch((error) => {
      reportSentry(error);
      dispatch(showLoading(false));
    });
  return api;
};

export const deleteSchedule = (id) => async (dispatch) => {
  const api = await requestDelete(`schedules/${id}`, null, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Delete Schedule Success',
        });
        dispatch(showLoading(false));
        return 200;
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Delete Schedule Failed',
      });
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      reportSentry(error);
    });
  return api;
};

export const updateSchedule = (id, data) => async (dispatch) => {
  let url = `schedules/${id}`;
  // eslint-disable-next-line no-prototype-builtins
  if (data.hasOwnProperty('overlap')) {
    if (data.overlap) {
      url = `schedules/${id}?overlap=true`;
    }
  }
  dispatch(showLoading(true));
  const api = await requestPut(url, null, data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch({
          type: IS_SUCCESS,
          value: true,
          message: 'Update Schedule Success',
        });
        dispatch(showLoading(false));
        return true;
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      dispatch({
        type: IS_SUCCESS,
        value: false,
        message: 'Update Schedule Failed',
      });
      const codeErr = reportSentry(error);
      notification.error({
        message: 'Mohon Maaf',
        description: `Perubahan Jadwal Gagal Harap Hubungi Kami Dengan Menyertakan Kode [${codeErr}]`,
      });
    });
  return api;
};

export const createBulkSchedule = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  // eslint-disable-next-line no-undef
  return new Promise((resolve) => {
    let isGood = true;
    data.map(async (v) => {
      await requestPost('schedules?overlap=false', v, getUserToken())
        .then((response) => {
          if (!response.status_code === 200) {
            isGood = false;
            return true;
          }
        })
        .catch(() => {
          isGood = false;
        });
    });
    resolve(isGood);
    dispatch({
      type: IS_SUCCESS,
      value: true,
      message: 'Create Schedule Success',
    });
    dispatch(showLoading(false));
  });
};

export const createImportSchedules = (data) => async (dispatch) => {
  dispatch(showLoading(true));
  const api = await requestPost('schedules/import', data, getUserToken())
    .then((response) => {
      if (response.status_code === 200) {
        dispatch(showLoading(false));
        return true;
      }
      dispatch(showLoading(false));
    })
    .catch((error) => {
      dispatch(showLoading(false));
      notification.error({
        message: 'Mohon Maaf!',
        description: error.response.data.message,
      });
      dispatch(showLoading(false));
      reportSentry(error);
    });
  return api;
};
