/*eslint-disable */
import {requestDelete, requestGet, requestPost, requestPut} from "@configs";
import {
    CREATE_TUGAS,
    DETAIL_HISTORY,
    GET_ASSESSMENT_STUDENT_REFERENCE,
    GET_ASSESSMENT_STUDENT_REFERENCE_HISTORY,
    IS_SUCCESS,
    IS_UPLOAD_SUCCESS,
    PUT_ASSESSMENT_STUDENT,
    TUGAS_DETAIL,
    TUGAS_GURU,
    TUGAS_GURU_HISTORY,
    TUGAS_REFERENCE,
    TUGAS_SISWA,
    TUGAS_SISWA_HISTORY,
    UPLOAD,
} from "@type";
import {getUserToken} from "@utils";
import {notification} from "antd";
import {showLoading} from "@store/actions";
import axios from "axios";
import {reportSentry} from "../../../../utils/Utils";

export const getAssessments =
    (id, page, limit, role, params) => async (dispatch) => {
        const api = await requestGet(
            `me/assessments`,
            {
                class_id: id,
                limit: limit,
                page: page,
                sortby: "end_date",
                order: "desc",
                status: ["BELUM DIKERJAKAN"].join(","),
                type: role,
                ...params,
            },
            getUserToken(),
        )
            .then((response) => {
                dispatch({ type: TUGAS_SISWA, value: response.data });
            })
            .catch((error) => {
                notification.error({
                    message: "Mohon Maaf!",
                    description: error?.response?.message,
                });
                reportSentry(error);
            });
        return api;
    };

export const getDetailAssessmentsHistory = (id) => async (dispatch) => {
    const api = await requestGet(
        `assessmentReferenceHistory?assessment_reference_id=${id}`,
        null,
        getUserToken(),
    )
        .then((response) => {
            console.log(response.data);
            dispatch({ type: DETAIL_HISTORY, value: response.data });
        })
        .catch((error) => {
            notification.error({
                message: "Mohon Maaf!",
                description: error?.response?.message,
            });
            reportSentry(error);
        });
    return api;
};
export const getAssessmentsHistory =
    (id, page, limit, role, params) => async (dispatch) => {
        const api = await requestGet(
            `me/assessments`,
            {
                class_id: id,
                limit: limit,
                page: page,
                sortby: "submit_at",
                order: "desc",
                status: ["DIKUMPULKAN", "DINILAI"].join(","),
                type: role,
                ...params,
            },
            getUserToken(),
        )
            .then((response) => {
                dispatch({ type: TUGAS_SISWA_HISTORY, value: response.data });
            })
            .catch((error) => {
                notification.error({
                    message: "Mohon Maaf!",
                    description: "Ada Kesalahan",
                });
                reportSentry(error);
            });
        return api;
    };

export const newGetAssestmentBySiswa =
    (
        id,
        page,
        limit,
        end_date_from,
        end_date_to,
        role,
        course_id,
        search,
        status,
        params = {}
    ) =>
    async (dispatch) => {
        const api = await requestGet(
            `me/assessments`,
            {
                class_id: id,
                limit: limit,
                page: page,
                sortby: "end_date",
                order: "desc",
                end_date_from: end_date_from,
                end_date_to: end_date_to,
                search: search,
                type: role,
                course_id: course_id,
                status: status,
                ...params,
            },
            getUserToken(),
        )
            .then((response) => {
                dispatch({ type: TUGAS_SISWA, value: response.data });
            })
            .catch((error) => {
                notification.error({
                    message: "Mohon Maaf!",
                    description: "Ada Kesalahan",
                });
                reportSentry(error);
            });
        return api;
    };

export const newGetAssestmentByTeacher =
    (
        id,
        page,
        limit,
        end_date_from,
        end_date_to,
        role,
        course_id,
        search,
        start_status,
        params = {},
    ) =>
    async (dispatch) => {
        const api = await requestGet(
            `me/assessments-guru`,
            {
                access: role,
                class_id: id,
                limit: limit,
                page: page,
                end_date_from: end_date_from,
                end_date_to: end_date_to,
                course_id: course_id,
                search: search,
                start_status: start_status,
                ...params,
            },
            getUserToken(),
        )
            .then((response) => {
                dispatch({ type: TUGAS_GURU, value: response.data });
                if (response.status_code === 200) return true;
            })
            .catch((error) => {
                notification.error({
                    message: "Mohon Maaf!",
                    description: "Ada Kesalahan",
                });
                reportSentry(error);
            });
        return api;
    };

export const getAssestmentByTeacher = (params) => async (dispatch) => {
    const api = await requestGet(`me/assessments-guru`, params, getUserToken())
        .then((response) => {
            dispatch({ type: TUGAS_GURU, value: response.data });
            if (response.status_code === 200) return true;
        })
        .catch((error) => {
            notification.error({
                message: "Mohon Maaf!",
                description: "Ada Kesalahan",
            });
            reportSentry(error);
        });
    return api;
};
export const getAssestmentByTeacherHistory =
    (id, page, limit, endDate, role, params) => async (dispatch) => {
        const api = await requestGet(
            `me/assessments-guru`,
            {
                access: role,
                class_id: id,
                limit: limit,
                page: page,
                end_date_to: endDate,
                ...params,
            },
            getUserToken(),
        )
            .then((response) => {
                dispatch({ type: TUGAS_GURU_HISTORY, value: response.data });
                if (response.status_code === 200) return true;
            })
            .catch((error) => {
                notification.error({
                    message: "Mohon Maaf!",
                    description: "Ada Kesalahan",
                });
                reportSentry(error);
            });
        return api;
    };

export const getAssessmentsDetail = (id) => async (dispatch) => {
    return await requestGet(`assessments-v2/${id}`, false, getUserToken())
        .then((response) => {
            dispatch({ type: TUGAS_DETAIL, value: response.data });
            return response.data;
        })
        .catch((error) => {
            notification.error({
                message: "Mohon Maaf!",
                description: "Ada Kesalahan",
            });
            reportSentry(error);
        });
};
export const getAssessmentsDetailReference = (id) => async (dispatch) => {
    const api = await requestGet(
        `assessments/${id}/reference`,
        false,
        getUserToken(),
    )
        .then((response) => {
            dispatch({ type: TUGAS_REFERENCE, value: response.data });
        })
        .catch((error) => {
            notification.error({
                message: "Mohon Maaf!",
                description: "Ada Kesalahan",
            });
        });
    return api;
};
export const createTugas = (data) => async (dispatch) => {
    dispatch(showLoading(true));
    const api = await requestPost(`assessments-v3`, data, getUserToken())
        .then((response) => {
            if (response.status_code === 200) {
                dispatch({
                    type: IS_SUCCESS,
                    value: true,
                    message: "Create Tugas Success",
                });
                dispatch({ type: CREATE_TUGAS, value: response.data });
                dispatch(showLoading(false));
                return true;
            } else {
                dispatch({
                    type: IS_SUCCESS,
                    value: false,
                    message: "Create Tugas Failed",
                });
            }
        })
        .catch((error) => {
            notification.error({
                message: "Mohon Maaf!",
                description: error?.response?.message,
            });
            dispatch({
                type: IS_SUCCESS,
                value: false,
                message: "Create Tugas Failed",
            });
            dispatch(showLoading(false));
            reportSentry(error);
        });
    return api;
};

export const putAssessmentStudent = (id, idRef, data) => async (dispatch) => {
    dispatch(showLoading(true));
    const api = await requestPut(
        `assessments/${id}/reference/${idRef}`,
        null,
        data,
        getUserToken(),
    )
        .then((response) => {
            if (response.status_code === 200) {
                dispatch({
                    type: IS_SUCCESS,
                    value: true,
                    message: "Pengumpulan Tugas Sukses",
                });
                dispatch({
                    type: PUT_ASSESSMENT_STUDENT,
                    value: response.data,
                });
                dispatch(showLoading(false));
                return true;
            } else {
                dispatch({
                    type: IS_SUCCESS,
                    value: false,
                    message: "Pengumpulan Tugas Gagal",
                });
                dispatch(showLoading(false));
            }
        })
        .catch((error) => {
            notification.error({
                message: "Mohon Maaf!",
                description: error.response.message,
            });
            dispatch({
                type: IS_SUCCESS,
                value: false,
                message: "Pengumpulan Tugas Gagal",
            });
            dispatch(showLoading(false));
            reportSentry(error);
        });
    return api;
};
export const getAssessmentStudentReference =
    (id, idRef, sendToPatch = true) =>
    async (dispatch) => {
        dispatch(showLoading(true));
        return await requestGet(
            `assessments/${id}/reference/${idRef}`,
            false,
            getUserToken(),
        )
            .then((response) => {
                if (sendToPatch) {
                    dispatch({
                        type: GET_ASSESSMENT_STUDENT_REFERENCE,
                        value: response.data,
                    });
                    dispatch(showLoading(false));
                    return true;
                } else {
                    dispatch(showLoading(false));
                    return response.data;
                }
            })
            .catch((error) => {
                dispatch(showLoading(false));
                reportSentry(error);
            });
    };

export const getAssessmentStudentReferenceHistory =
    (idRef) => async (dispatch) => {
        dispatch(showLoading(true));
        const api = await requestGet(
            `assessments/reference/${idRef}`,
            false,
            getUserToken(),
        )
            .then((response) => {
                dispatch({
                    type: GET_ASSESSMENT_STUDENT_REFERENCE_HISTORY,
                    value: response.data,
                });
                dispatch(showLoading(false));
            })
            .catch((error) => {
                dispatch(showLoading(false));
                reportSentry(error);
            });
        return api;
    };

export const getAssessmentStudentReferenceCb = async (id, idRef, data) => {
    return await new Promise((resolve, reject) => {
        requestGet(
            `assessments/${id}/reference/${idRef}`,
            false,
            getUserToken(),
        )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
                reportSentry(error);
            });
    });
};

export const beriNilai = (id, idRef, nilai) => async (dispatch) => {
    dispatch(showLoading(true));
    const api = await requestPut(
        `assessments/${id}/reference/${idRef}/nilai?nilai=${nilai}`,
        null,
        null,
        getUserToken(),
    )
        .then((response) => {
            dispatch({ type: "BERI_NILAI", value: response.data });
            dispatch(showLoading(false));
            notification.success({
                message: "Anda Berhasil Menambahkan Nilai",
            });
            return true;
        })
        .catch((error) => {
            reportSentry(error);
            notification.error({
                message: "Maaf Siswa Ini Belum Mengumpulkan Ujian",
            });
            dispatch(showLoading(false));
        });
    return api;
};
export const tambahComment = (id, idRef, data) => async (dispatch) => {
    dispatch(showLoading(true));
    const api = await requestPost(
        `assessments/${id}/reference/${idRef}/comment`,
        data,
        getUserToken(),
    )
        .then((response) => {
            if (response.status_code === 400) {
                dispatch(showLoading(false));
            }
            dispatch({ type: "TAMBAH_COMMENT", value: "success" });
            dispatch(showLoading(false));
            return true;
        })
        .catch((error) => {
            dispatch(showLoading(false));
            reportSentry(error);
        });
    return api;
};
export const uploadFile = (file, type, parent) => async (dispatch) => {
    const acc = localStorage.getItem("acc");
    dispatch(showLoading(true));
    const api = await axios({
        url: "https://www.googleapis.com/upload/drive/v3/files",
        params: { uploadType: "multipart", fields: "*", access_token: acc },
        headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${acc}`,
        },
        data: file,
        method: "post",
    })
        .then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: IS_UPLOAD_SUCCESS,
                    value: true,
                    message: "Unggah File Success",
                });
                dispatch(createPermissionGDrive(response.data.id));
                if (type === "dokumen") {
                    dispatch(patchDokumen(response.data.id, parent));
                }
                dispatch(showLoading(false));
                if (type === "Edit") {
                    return response.data;
                }
                dispatch({ type: UPLOAD, value: response.data });
            }
        })
        .catch((error) => {
            // if (error.response.status === 401) {
            // 	notification.error({
            // 		message: 'Mohon Maaf!',
            // 		description: 'Gagal! Harap Login Ulang'
            // 	});
            // }
            dispatch({
                type: IS_UPLOAD_SUCCESS,
                value: false,
                message: "Ungga File Failed",
            });
            dispatch(showLoading(false));
            reportSentry(error);
        });

    return api;
};

const createPermissionGDrive = (id) => async (dispatch) => {
    const acc = localStorage.getItem("acc");
    const api = await axios({
        url: `https://www.googleapis.com/drive/v3/files/${id}/permissions`,
        // params: { fields: '*', access_token: acc, uploadType: 'media' },
        // headers: { 'content-type': 'multipart/form-data' },
        headers: {
            Authorization: `Bearer ${acc}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        data: { role: "writer", type: "anyone" },
        method: "post",
    })
        .then((response) => {})
        .catch((error) => {
            reportSentry(error);
        });
    return api;
};

const patchDokumen = (id, parent) => async (dispatch) => {
    const acc = localStorage.getItem("acc");
    const api = await axios({
        url: `https://www.googleapis.com/drive/v3/files/${id}`,
        params: { addParents: parent[0], fields: "*", access_token: acc },
        // headers: { 'content-type': 'multipart/form-data' },
        // data: { role: 'writer', type: 'anyone' },
        method: "patch",
    })
        .then((response) => {
            notification.success({
                message: "Success!",
                description: "Berhasil Menambahkan Dokumen",
            });
        })
        .catch((error) => {
            notification.error({
                message: "Mohon Maaf!",
                description:
                    error.response?.status === 403
                        ? "Gagal, anda tidak punya akses ke folder tersebut. Hubungi admin untuk meminta akses!"
                        : "Gagal Menambahkan Dokumen",
            });
        });
    return api;
};

export const editTugas = (id, data) => async (dispatch) => {
    dispatch(showLoading(true));
    const api = await requestPut(
        `assessments-v2/${id}`,
        false,
        data,
        getUserToken(),
    )
        .then((response) => {
            if (response.status_code === 200) {
                dispatch(showLoading(false));
                return true;
            }
            return false;
        })
        .catch((error) => {
            dispatch(showLoading(false));
            notification.error({
                message: "Update Tugas Gagal ",
            });
            return false;
        });
    return api;
};
export const deleteTugas = (id, data) => async (dispatch) => {
    dispatch(showLoading(true));
    const api = await requestDelete(`assessments/${id}`, null, getUserToken())
        .then((response) => {
            if (response.status_code === 200) {
                dispatch(showLoading(false));
                return true;
            }
            return false;
            dispatch(showLoading(false));
        })
        .catch((error) => {
            dispatch(showLoading(false));
            return false;
        });
    return api;
};
